.power {
    font-weight: bold;
    background-color: #ff0000;
    color: white;
}

.grade-a {
    background-color: #b6d7a8;
}

.grade-b {
    background-color: #d9ead3;
}

.grade-c {
    background-color: #fff2cc;
}

.grade-d {
    background-color: #f9cb9c;
}

.grade-f {
    background-color: #ea9999;
}

.score-advanced {
    background-color: #674ea7;
    color: white;
}

.score-proficient {
    background-color: #a4c2f4;
    color: white;
}

.score-high-passing {
    background-color: #6aa84f;
    color: white;
}

.score-passing {
    background-color: #6aa84f;
    color: white;
}

.score-low-passing {
    background-color: #b6d7a8;
    color: white;
}

.score-high-basic {
    background-color: #f1c232;
    color: white;
}

.score-basic {
    background-color: #f1c232;
    color: white;
}
.score-basic-passing {
    background-color: #6aa84f;
    color: white;
}

.score-low-basic {
    background-color: #e69138;
    color: white;
}

.score-high-minimal {
    background-color: #e06666;
    color: white;
}

.score-minimal {
    background-color: #e06666;
    color: white;
}

.score-low-minimal {
    background-color: #ff0000;
    color: white;
}

.score-no-score {
    background-color: #808080;
}

.wk-score-bronze {
    background-color: #d97706;
    color: white;
}

.wk-score-silver {
    background-color: #a1a1aa;
    color: white;
}

.wk-score-gold {
    background-color: #f59e0b;
    color: white;
}

.wk-score-platinum {
    background-color: #52525b;
    color: white;
}

.wk-score-no-score {
    background-color: #ff0000;
    color: white;
}

.act-benchmark-true {
    background-color: #16a34a;
    color: white;
}

.act-benchmark-false {
    background-color: #dc2626;
    color: white;
}

.true {
    background-color: #6aac42;
    color: white;
}

.false {
    background-color: #e74c39;
    color: white;
}

.participation-rate-pass {
    background-color: #b6d7a8;
    color: black;
}

.participation-rate-fail {
    background-color: #ea9999;
    color: black;
}

.growth-0 {
    background-color: #e74c39;
    color: white;
}

.growth-1 {
    background-color: #6aac42;
    color: white;
}

.growth-125 {
    background-color: #6aac42;
    color: white;
}

.growth-no {
    background-color: #808080;
}

.lps-true {
    background-color: #6aac42;
    color: white
}
.lps-false {
    background-color: #808080;
    color: black
}

.fifty_color_scale_50 {
    background-color: #b6d7a8;
    color: black
}

.fifty_color_scale_40 {
    background-color: #b6d7a8;
    color: black
}

.fifty_color_scale_30 {
    background-color: #d9ead3;
    color: black
}

.fifty_color_scale_20 {
    background-color: #fff2cc;
    color: black
}

.fifty_color_scale_10 {
    background-color: #f9cb9c;
    color: black
}

.fifty_color_scale_00 {
    background-color: #ea9999;
    color: black
}

.onehundred_color_scale_120 {
    background-color: #b6d7a8;
    color: black
}

.onehundred_color_scale_110 {
    background-color: #b6d7a8;
    color: black
}

.onehundred_color_scale_100 {
    background-color: #b6d7a8;
    color: black
}

.onehundred_color_scale_90 {
    background-color: #b6d7a8;
    color: black
}

.onehundred_color_scale_80 {
    background-color: #b6d7a8;
    color: black
}

.onehundred_color_scale_70 {
    background-color: #d9ead3;
    color: black
}

.onehundred_color_scale_60 {
    background-color: #d9ead3;
    color: black
}

.onehundred_color_scale_50 {
    background-color: #fff2cc;
    color: black
}

.onehundred_color_scale_40 {
    background-color: #fff2cc;
    color: black
}

.onehundred_color_scale_30 {
    background-color: #f9cb9c;
    color: black
}

.onehundred_color_scale_20 {
    background-color: #f9cb9c;
    color: black
}

.onehundred_color_scale_10 {
    background-color: #ea9999;
    color: black
}

.onehundred_color_scale_00 {
    background-color: #ea9999;
    color: black
}

.twohundred_color_scale_200 {
    background-color: #b6d7a8;
    color: black
}

.twohundred_color_scale_190 {
    background-color: #b6d7a8;
    color: black
}

.twohundred_color_scale_180 {
    background-color: #b0c5a7;
    color: black
}

.twohundred_color_scale_170 {
    background-color: #b0c5a7;
    color: black
}

.twohundred_color_scale_160 {
    background-color: #d1e7c8;
    color: black
}

.twohundred_color_scale_150 {
    background-color: #fff2cc;
    color: black
}

.twohundred_color_scale_140 {
    background-color: #fff2cc;
    color: black
}

.twohundred_color_scale_130 {
    background-color: #fff2cc;
    color: black
}

.twohundred_color_scale_120 {
    background-color: #fff2cc;
    color: black
}

.twohundred_color_scale_110 {
    background-color: #fff2cc;
    color: black
}

.twohundred_color_scale_100 {
    background-color: #fff2cc;
    color: black
}

.twohundred_color_scale_90 {
    background-color: #fff2cc;
    color: black
}

.twohundred_color_scale_80 {
    background-color: #fff2cc;
    color: black
}

.twohundred_color_scale_70 {
    background-color: #fff2cc;
    color: black
}

.twohundred_color_scale_60 {
    background-color: #f9cb9c;
    color: black
}

.twohundred_color_scale_50 {
    background-color: #f9cb9c;
    color: black
}

.twohundred_color_scale_40 {
    background-color: #f9cb9c;
    color: black
}

.twohundred_color_scale_30 {
    background-color: #f9cb9c;
    color: black
}

.twohundred_color_scale_20 {
    background-color: #ea9999;
    color: black
}

.twohundred_color_scale_10 {
    background-color: #ea9999;
    color: black
}

.twohundred_color_scale_00 {
    background-color: #ea9999;
    color: black
}

/* Red (0) */
.el_color_scale_0 {
    background-color: #ea9999;
}

/* Orange (1% to 19%) */
.el_color_scale_1 {
    background-color: #f9cb9c;
}

/* Yellow (20% to 39%) */
.el_color_scale_2 {
    background-color: #fff2cc;
}

/* Light Green (40% to 59%) */
.el_color_scale_3 {
    background-color: #d9ead3;
}

/* Green (60% to max) */
.el_color_scale_4 {
    background-color: #b6d7a8;
}

.btn-secondary {
    @apply bg-green-600 text-white focus:ring-green-500;

    &:hover,
    &:focus {
        @apply bg-green-500 text-white;
    }

    &.btn-outline {
        @apply bg-transparent border border-green-600 text-green-600 shadow-none;

        &:hover,
        &:focus {
            @apply bg-green-500 text-white;
        }
    }
}

.btn-tertiary {
    @apply bg-gray-700 text-white focus:ring-gray-600;

    &:hover,
    &:focus {
        @apply bg-gray-600 text-white;
    }

    &.btn-outline {
        @apply bg-transparent border border-gray-700 text-gray-700 shadow-none;

        &:hover,
        &:focus {
            @apply bg-gray-600 text-white;
        }
    }
}

.btn-purple {
    @apply bg-purple-600 text-white focus:ring-purple-500;

    &:hover,
    &:focus {
        @apply bg-purple-500 text-white;
    }

    &.btn-outline {
        @apply bg-transparent border border-purple-500 text-purple-500 shadow-none;

        &:hover,
        &:focus {
            @apply bg-purple-500 text-white;
        }
    }
}